<template>
  <div class="register_container_bv">
    <!-- 登录 -->
    <video muted="true" loop="" autoplay="autoplay" class="bgvideo">
      <source type="video/mp4" src="../../assets/img/bv/night-banner.mp4" />
    </video>
    <div class="rigister">
      <!-- title  -->
      <h1 class="title">{{ translateTitle("注册账号") }}</h1>
      <!-- tab切换 -->
      <div class="tab-change-o">
        <div class="tab-change">
          <el-tabs v-model="currentType" class="tabs" @tab-click="changeTab">
            <!-- <el-tab-pane key="2" name="1">
              <span slot="label">{{ translateTitle("用户名") }}</span>
            </el-tab-pane> -->
            <el-tab-pane key="1" name="2">
              <span slot="label">{{ translateTitle("邮箱") }}</span>
            </el-tab-pane>
            <el-tab-pane key="0" name="3">
              <span slot="label">{{ translateTitle("手机号") }}</span>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <!-- 表单 -->
      <div class="rigister-form">
        <el-form
          ref="register_ref"
          :rules="formRules"
          :model="registerData"
          label-position="top"
          @submit.native.prevent
        >
          <!-- 区号 - 手机号 -->
          <div class="area-mobile" v-if="currentType == 3">
            <el-form-item prop="area">
              <el-select
                @input.native="filterData"
                ref="searchSelect"
                v-model="registerData.area"
                filterable
              >
                <el-option
                  v-for="(item, index) in all_areacode"
                  :value="item.phone_code"
                  :label="
                    '+' +
                      item.phone_code +
                      `               ${item.iso} ${item.iso3} ${item.jp} ${item.kr} ${item.zh_CN} ${item.zh_HK}`
                  "
                  :key="index"
                >
                  <p
                    style="
                      width: 360px;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>{{ item[language] }}</span>
                    <span>+ {{ item.phone_code }}</span>
                  </p>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mobile">
              <InputNumber
                v-model="registerData.mobile"
                :placeholder="translateTitle('手机号')"
                maxlength="20"
              ></InputNumber>
            </el-form-item>
          </div>

          <!-- 邮箱 -->
          <el-form-item prop="email" class="email" v-if="currentType == 2">
            <el-input
              v-model.trim="registerData.email"
              :placeholder="translateTitle('邮箱')"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <!-- 发送验证码 可以直接用 -->

          <!-- 账号 -->
          <!-- <el-form-item prop="account" v-if="currentType == 3">
            <el-input placeholder="请输入账号" maxlength="50"></el-input>
          </el-form-item>-->
          <el-form-item prop="account" class="account" v-if="currentType == 1">
            <el-input
              v-model.trim="registerData.account"
              :placeholder="translateTitle('用户名')"
              maxlength="50"
            ></el-input>
          </el-form-item>

          <!-- 密码 -->
          <el-form-item prop="pwd">
            <el-input
              v-model.trim="registerData.pwd"
              autocomplete="new-password"
              :key="passwordType"
              :type="passwordType"
              :placeholder="translateTitle('密码')"
              maxlength="20"
            >
              <img
                v-if="passwordType === 'password'"
                slot="suffix"
                @click="handlePassword(1)"
                src="../../assets/img/login/eye-off.png"
              />
              <img
                v-else
                class="open"
                slot="suffix"
                @click="handlePassword(1)"
                src="../../assets/img/login/eye-open.png"
              />
            </el-input>
          </el-form-item>
          <!-- 确认密码 -->
          <el-form-item prop="confirm_pwd">
            <el-input
              :key="pwdTypeConfird"
              :type="pwdTypeConfird"
              v-model.trim="registerData.confirm_pwd"
              autocomplete="new-password"
              :placeholder="translateTitle('再次输入密码')"
              maxlength="20"
            >
              <img
                v-if="pwdTypeConfird === 'password'"
                slot="suffix"
                @click="handlePassword(2)"
                src="../../assets/img/login/eye-off.png"
              />
              <img
                v-else
                class="open"
                slot="suffix"
                @click="handlePassword(2)"
                src="../../assets/img/login/eye-open.png"
              />
            </el-input>
          </el-form-item>

          <!-- 谷歌验证码 -->
          <!-- <el-form-item
            prop="google_code"
            v-if="currentType == 1"
            class="verification-code"
          >
            <div class="verification">
              <InputNumber
                v-model="registerData.google_code"
                autocomplete="off"
                :placeholder="translateTitle('谷歌码')"
                maxlength="6"
              ></InputNumber>
            </div>
          </el-form-item> -->
          <!-- <el-form-item v-if="currentType == 1">
            <div class="gogle-details">
              <div class="gogle-remark">
                <span class="remark-top">
                  {{ translateTitle("打开谷歌浏览器，扫描下方二维码或手动输") }}
                </span>
                <span class="remark-bottom">{{
                  translateTitle("入下述秘钥添加验证令牌")
                }}</span>
              </div>

              <div class="qr_code">
                <qrcode-vue
                  class="qr_code_canvas"
                  v-show="googleCode"
                  :value="googleCode"
                  :size="100"
                  level="H"
                ></qrcode-vue>
                <p>
                  <span>{{ translateTitle("密钥：") }}</span>
                  <span class="secret-key">{{ secret }}</span>
                  <i
                    class="el-icon-document-copy"
                    style="color: #9aa1b1"
                    v-clipboard:copy="secret"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyError"
                  ></i>
                </p>
              </div>
            </div>
          </el-form-item> -->
          <div class="invite-code">
            <span class="code">
              {{ translateTitle("是否有邀请码？") }}
              <i
                @click="onToogleAdvanced"
                :class="
                  'iconfont' +
                    ' ' +
                    `${advanced ? 'iconxialashouqi' : 'iconsize16-1'}`
                "
              ></i
            ></span>
            <el-form-item v-if="advanced" class="invite-code">
              <el-input
                v-model="registerData.invite_code"
                autocomplete="off"
                :placeholder="
                  translateTitle('邀请码') + ' ' + translateTitle('(选填)')
                "
                maxlength="20"
              ></el-input>
            </el-form-item>
          </div>

          <div class="agreement">
            <el-checkbox v-model="checked">
              <span class="title">{{ translateTitle("我已阅读并同意") }}</span>
              <a class="user-agreement" :href="serviceUrl" target="_blank">{{
                translateTitle("《用户协议》")
              }}</a>
              <span class="user-agreement">{{ translateTitle("和") }}</span>
              <a :href="privacyUrl" class="user-agreement" target="_blank">{{
                translateTitle("《隐私条款》")
              }}</a>
            </el-checkbox>
          </div>

          <!-- 登录 -->
          <el-button
            class="rigister-btn theme-btn"
            type="primary"
            @click="registerClickShow"
          >
            {{ translateTitle("注册") }}
          </el-button>
        </el-form>
      </div>
      <div class="register-forget">
        <p>
          <span
            >{{ translateTitle("已有账号？") }} {{ translateTitle("去") }}</span
          >
          <router-link class="register" :to="routeSplicing(language, 'login')">
            {{ translateTitle("登录") }}</router-link
          >
        </p>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :modal-append-to-body="false"
      width="500px"
      :title="codeTitle"
      @close="onCloseCode"
      class="resiger-verification"
      :visible.sync="codeVisible"
    >
      <div class="content">
        <div class="sub-title">
          <div v-if="currentType != 1">
            <span>{{ translateTitle("请输入您的") }}</span>
            {{ translateTitle(subType) }}
            <span class="mobile-email">{{ subTitle }}</span>
            {{ translateTitle("收到的验证码") }}
          </div>
          <div v-else>
            <span>{{
              translateTitle("请扫描下方二维码或输入下述密钥添加验证令牌")
            }}</span>
          </div>
        </div>
        <div v-if="currentType == 2" class="email-title">
          {{ translateTitle("邮箱验证码可能被判定为垃圾邮件，请注意查收。") }}
        </div>
        <div class="code-form">
          <el-form
            ref="code_ref"
            :rules="codeRules"
            :model="codeData"
            label-position="top"
            @submit.native.prevent
          >
            <el-form-item
              prop="mobile_code"
              v-if="currentType == 3"
              class="verification-code"
            >
              <div class="verification">
                <InputNumber
                  v-model="codeData.mobile_code"
                  autocomplete="off"
                  :placeholder="translateTitle('手机验证码')"
                  maxlength="6"
                ></InputNumber>
              </div>
            </el-form-item>
            <el-form-item
              prop="email_code"
              v-if="currentType == 2"
              class="verification-code"
            >
              <div class="verification">
                <InputNumber
                  v-model="codeData.email_code"
                  autocomplete="off"
                  :placeholder="translateTitle('邮箱验证码')"
                  maxlength="6"
                ></InputNumber>
              </div>
              <el-form-item
                prop="google_code"
                v-if="currentType == 1"
                class="verification-code"
              >
                <div class="verification">
                  <InputNumber
                    v-model="codeData.google_code"
                    autocomplete="off"
                    :placeholder="translateTitle('谷歌验证码')"
                    maxlength="6"
                  ></InputNumber>
                </div>
              </el-form-item>
            </el-form-item>
            <el-form-item v-if="currentType == 1">
              <div class="gogle-details">
                <!-- <div class="gogle-remark">
                    <span class="remark-top">
                      {{
                        translateTitle("打开谷歌浏览器，扫描下方二维码或手动输")
                      }}
                    </span>
                    <span class="remark-bottom">{{
                      translateTitle("入下述秘钥添加验证令牌")
                    }}</span>
                  </div> -->

                <div class="qr_code">
                  <qrcode-vue
                    class="qr_code_canvas"
                    v-show="googleCode"
                    :value="googleCode"
                    :size="100"
                    level="H"
                  ></qrcode-vue>
                  <p>
                    <span>{{ translateTitle("密钥：") }}</span>
                    <span class="secret-key">{{ secret }}</span>
                    <i
                      class="iconfont iconicon-copy"
                      style="color: #9aa1b1"
                      v-clipboard:copy="secret"
                      v-clipboard:success="copySuccess"
                      v-clipboard:error="copyError"
                    ></i>
                  </p>
                </div>
              </div>
              <!-- 谷歌验证码 -->
              <el-form-item
                prop="google_code"
                v-if="currentType == 1"
                class="verification-code"
              >
                <div class="verification">
                  <InputNumber
                    v-model="codeData.google_code"
                    autocomplete="off"
                    :placeholder="translateTitle('谷歌码')"
                    maxlength="6"
                  ></InputNumber>
                </div>
              </el-form-item>
            </el-form-item>

            <div class="footer-btootns">
              <el-button
                @click="onSubmit"
                :loading="submitLoading"
                class="common-save submit theme-btn"
                >{{ translateTitle("确认") }}</el-button
              >
              <div v-if="currentType != 1" class="get-code">
                <span class="title">{{
                  translateTitle("没有收到验证码？")
                }}</span>

                <div class="count-down">
                  <span
                    class="code-title"
                    v-if="
                      mobileCountDown.countDown === 0 ||
                        mobileCountDown.countDown === -1
                    "
                    @click="clickFetch"
                    >{{ translateTitle("获取验证码") }}</span
                  >
                  <span class="disabled" v-else>{{
                    mobileCountDown.countDown + "s"
                  }}</span>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <Vcode
      @close="onCloseVcode"
      :imgs="imgs"
      :show="isShow"
      @success="success"
      :successText="translateTitle('验证通过')"
      :failText="translateTitle('验证失败，请重试')"
      :sliderText="translateTitle('拖动滑块完成拼图')"
    />
    <Geetest :isGeet="isShowGeet" :isRegister="true" @geetParam="success" />
    <!-- 版权 -->
    <!-- <div class="copyright">
      <span>Copyright &copy; 2020 - 2021, Topcredit All Rights Reserved</span>
    </div> -->
  </div>
</template>

<script>
import md5 from "js-md5";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import Vcode from "vue-puzzle-vcode"; //页面内引用插件
import Clipboard from "clipboard";
import * as Account from "@/model/user/account";
import * as Settings from "@/model/settings";
import { showMessage } from "@/components/dialog/message";
import {
  sendSignSmsCodeGeet,
  sendSignEmailCodeGeet,
  sendSignSmsCode,
  checkIp,
  sendSignEmailCode,
} from "@/model/user/security";
import InputNumber from "@/components/common/InputNumber";
import img1 from "../../assets/img/rePhoto/1.png";
import img2 from "../../assets/img/rePhoto/2.png";
// import Cookies from "js-cookie";
import QrcodeVue from "qrcode.vue";
import encryption from "@/assets/js/encryption";
import { translateTitle } from "@/assets/js/i18n";
import { mapActions, mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
import Geetest from "@/components/Geetest.vue";
export default {
  data() {
    return {
      img1,
      img2,
      imgs: [],
      codeVisible: false,
      isShow: false,
      emailCountDown: {
        countDown: -1, // 倒计时计数
        countDownTimer: null, // 倒计时容器
        countDownStatus: false, // 倒计时点击状态
      },
      mobileCountDown: {
        countDown: -1, // 倒计时计数
        countDownTimer: null, // 倒计时容器
        countDownStatus: false, // 倒计时点击状态
      },
      passwordType: "password",
      pwdTypeConfird: "password",
      serviceUrl: "",
      areaCode: "EN",
      privacyUrl: "",
      checked: true,
      advanced: true,
      registerLoading: false, // 登录loading
      currentType: "2", // 当前选项
      registerData: {}, // 登录数据
      all_areacode: [], // 所有区号
      subType: "",
      codeTitle: "",
      subTitle: "",
      secret: "", //谷歌秘钥
      submitLoading: false,
      codeData: {
        mobile_code: "",
        email_code: "",
        google_code: "",
      },
      isShowGeet: false,
      geetParam: null,
      isFetch: false,
      formRules: {
        area: [
          {
            required: true,
            message: this.translateTitle(`请选择区号`),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: this.translateTitle(`请输入手机号`),
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: this.translateTitle(`请输入用户名`),
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z][A-Za-z0-9_]{4,15}$/,
            message: this.translateTitle(
              `用户名长度需为5～16位，以字母开头，数字字母下划线组成`
            ),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.translateTitle(`请输入邮箱`),
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/,
            message: this.translateTitle(`邮箱格式不正确`),
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: this.translateTitle(`请输入密码`),
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: this.translateTitle(`密码长度为 6-20 位字符`),
            trigger: "blur",
          },
          {
            pattern: /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/,
            message: this.translateTitle(
              `密码必须包含英文和数字，不能包含空格`
            ),
            trigger: "blur",
          },
          // { pattern: /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/, message: '密码必须包含英文和数字，不能包含空格'}
        ],
        confirm_pwd: [{ validator: this.confirmBlur, trigger: "blur" }],
        mobile_code: [
          {
            required: true,
            message: this.translateTitle(`请输入手机验证码`),
            trigger: "blur",
          },
          {
            max: 6,
            message: this.translateTitle(`验证码应为 6 位数字`),
            trigger: "blur",
          },
        ],

        gogle_code: [
          {
            required: true,
            message: this.translateTitle(`请输入谷歌码`),
            trigger: "blur",
          },
          {
            max: 6,
            message: this.translateTitle(`谷歌码应为 6 位数字`),
            trigger: "blur",
          },
        ],
      },
      codeRules: {
        mobile_code: [
          {
            required: true,
            message: this.translateTitle(`请输入正确的6位手机验证码`),
            trigger: "blur",
          },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`请输入正确的6位手机验证码`),
          },
        ],
        email_code: [
          {
            required: true,
            message: this.translateTitle(`请输入正确的6位邮箱验证码`),
            trigger: "blur",
          },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`请输入正确的6位邮箱验证码`),
          },
        ],
        google_code: [
          {
            required: true,
            message: this.translateTitle(`请输入正确的6位谷歌码`),
            trigger: "blur",
          },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`请输入正确的6位谷歌码`),
          },
        ],
      },
    };
  },

  async created() {
    let clause = JSON.parse(sessionStorage.getItem("clause"));

    this.serviceUrl = clause[0].url;
    this.privacyUrl = clause[1].url;
    await this.initialData();
    this.getAreaCode();
    // this.GetGoogleSecret();

    document.onkeyup = e => {
      let keycode = document.all ? event.keyCode : e.which;
      // 在登录界面 且 按得键是回车键enter
      // if (this.$route.path == "/register" && keycode === 13) {
      //   this.registerClick();
      // }
    };
    if (
      VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" ||
      VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T" ||
      VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w"
    ) {
      this.imgs.push(img1);
    } else {
      this.imgs.push(img2);
    }
    // this.checkIp4Country();
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  mounted() {
    // this.ipArea();
    console.log(this.$route.params);
    if (JSON.stringify(this.$route.params) != "{}") {
      let name = this.$route.params.name;
      let val = this.$route.params.val;

      if (name == "phone") {
        this.currentType = "3";
        this.registerData.mobile = val;
      } else if (name == "email") {
        this.currentType = "2";
        this.registerData.email = val;
      } else if (name == "account") {
        this.currentType = "1";
        this.registerData.account = val;
      } else {
        this.currentType = "2";
        this.registerData.email = val;
      }
    } else {
      this.currentType = "2";
    }
    this.registerData.invite_code =
      this.$route.query.ref || this.$route.query.invite_code || "";
  },
  methods: {
    routeSplicing,
    translateTitle,

    async checkIp4Country() {
      const { data } = await checkIp();
      if (data.status == 200) {
        this.areaCode = "EN";
      } else {
        this.areaCode = "CN";
      }
    },
    success(geetParam) {
      this.geetParam = geetParam;
      if (!this.isFetch) {
        this.registerClick();
      } else {
        this.fetchCode();
      }
      // this.handLoginSuccess(this.sucessData, this.loginStatus, this.loginMsg);
      this.isShow = false; // 通过验证后，手动隐藏模态框
    },
    onCloseVcode() {
      this.isShow = false;
    },
    registerClickShow() {
      if (this.areaCode == "CN") {
        showMessage({
          message: this.translateTitle(
            `根據當地相關法令規範，本產品不支援您所在的地區，請見諒。`
          ),
          type: "error",
        });
        return;
      }
      this.$refs.register_ref.validate(async valid => {
        if (!valid) {
          return;
        }
        if (!this.checked) {
          showMessage({
            message: this.translateTitle(`请勾选用户协议`),
            type: "info",
          });
          return;
        }
        this.isFetch = false;
        // this.isShow = true;
        this.isShowGeet = !this.isShowGeet;
      });
    },
    onToogleAdvanced() {
      this.advanced = !this.advanced;
    },
    /* 确认密码校验 */
    confirmBlur(rule, value, callback) {
      let { pwd } = this.registerData;

      if ((value === "") | !value) {
        callback(new Error(this.translateTitle("请输入确认密码")));
      } else if (value !== pwd) {
        callback(new Error(this.translateTitle("两次输入密码不一致!")));
      } else {
        callback();
      }
    },
    filterData(val) {
      var str = this.$refs.searchSelect.$data.selectedLabel;
      if (str.length > 6) {
        this.$refs.searchSelect.$data.selectedLabel = str.substr(0, 5);
      }
    },
    handlePassword(type) {
      if (type == 1) {
        this.passwordType === "password"
          ? (this.passwordType = "")
          : (this.passwordType = "password");
        // this.$nextTick(() => {
        //   this.$refs.password.focus();
        // });
      } else {
        this.pwdTypeConfird === "password"
          ? (this.pwdTypeConfird = "")
          : (this.pwdTypeConfird = "password");
        // this.$nextTick(() => {
        //   this.$refs.password.focus();
        // });
      }
    },
    /* 切换tab */
    changeTab() {
      this.resetForm();
      // this.registerData.invite_code = "";
    },
    // 复制成功
    copySuccess() {
      showMessage({
        message: this.translateTitle(`内容复制成功`),
        type: "success",
      });
    },
    // 复制失败
    copyError() {
      showMessage({ message: this.translateTitle(`内容复制失败`) });
    },
    /**获取goole秘钥 */
    GetGoogleSecret() {
      Account.getAccountGoogleSecret().then(res => {
        if (res.status === 200) {
          this.secret = res.data.secret;
        } else {
          // showMessage({ message: res.msg });
        }
      });
    },
    /**复制gogle秘钥 */
    copyCode() {
      var clipboard = new Clipboard("#copy_code");

      clipboard.on("success", () => {
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        showMessage({ message: "该浏览器不支持自动复制", type: "warning" });

        // 释放内存
        clipboard.destroy();
      });
    },
    /* 注册点击 */
    async registerClick() {
      let {
        currentType,
        registerData: { area, mobile, email },
      } = this;
      // 手机
      if (currentType === "3") {
        this.subType = "手机号";
        this.subTitle = "+" + area + " " + mobile;

        this.codeTitle = this.translateTitle(`手机验证`);

        // const { status } = await sendSignSmsCode({
        const { status } = await sendSignSmsCodeGeet({
          type: 2,
          mobile,
          area,
          ...this.geetParam,
        });
        if (status == 200) {
          this.mobileCountDown.countDownStatus = true;
          this.$message({
            message: this.translateTitle(`验证码发送成功`),
            type: "success",
            customClass: "zZindex",
            duration: 1000,
          });
          // showMessage({ message: "验证码发送成功", type: "success",customClass:'zZindex' });
          this.mobileCountDown.countDown = 60;
          this.codeVisible = true;
          this.mobileCountDown.countDownTimer = setInterval(() => {
            if (this.mobileCountDown.countDown === 0) {
              clearInterval(this.mobileCountDown.countDownTimer);
              this.mobileCountDown.countDownStatus = false;
            }
            this.mobileCountDown.countDown--;
          }, 1000);
          this.codeVisible = true;
        } else {
          this.mobileCountDown.countDownStatus = false;
        }
        // 邮箱
      } else if (currentType == 2) {
        this.subType = "邮箱";
        this.subTitle = email;
        this.codeTitle = this.translateTitle(`邮箱验证`);
        // const { status } = await sendSignEmailCode({
        const { status } = await sendSignEmailCodeGeet({
          type: 32,
          email,
          ...this.geetParam,
        });
        if (status == 200) {
          this.mobileCountDown.countDownStatus = true;
          this.$message({
            message: this.translateTitle(`验证码发送成功`),
            type: "success",
            customClass: "zZindex",
            duration: 1000,
          });
          this.mobileCountDown.countDown = 60;
          this.codeVisible = true;
          this.mobileCountDown.countDownTimer = setInterval(() => {
            if (this.mobileCountDown.countDown === 0) {
              clearInterval(this.mobileCountDown.countDownTimer);
              this.mobileCountDown.countDownStatus = false;
            }
            this.mobileCountDown.countDown--;
          }, 1000);
        } else {
          this.mobileCountDown.countDownStatus = false;
        }
      } else {
        // this.codeVisible = true;
        // this.subTitle = "";
        // this.codeTitle = this.translateTitle(`绑定谷歌器验证`);

        let params = { ...this.geetParam };
        params.username = this.registerData.account;
        params.pwd = md5(this.registerData.pwd);
        params.invite_code = this.registerData.invite_code;

        this.submitLoading = true;
        // const { data, status } = await Account.accountV2SignUp(params);
        const { data, status } = await Account.accountSignUpGeet(params);
        this.submitLoading = false;
        if (status == 200) {
          showMessage({
            message: this.translateTitle(`注册成功`),
            type: "success",
          });
          this.registerSuccess(data, status, params);
        }
      }
    },
    onCloseCode() {
      this.codeData = {
        mobile_code: "",
        email_code: "",
        google_code: "",
      };
      clearInterval(this.mobileCountDown.countDownTimer);
      this.mobileCountDown.countDownStatus = false;
    },

    /* 点击确认 */
    onSubmit() {
      this.$refs.code_ref.validate(async valid => {
        if (!valid) {
          return;
        }
        let params = { ...this.registerData, ...this.codeData };
        params.pwd = md5(params.pwd);
        delete params.confirm_pwd;

        if (this.currentType == 3) {
          // 手机
          delete params.email;
          delete params.email_code;
          this.submitLoading = true;
          const { data, status } = await Account.mobileReg(params);
          this.submitLoading = false;
          if (status == 200) {
            showMessage({
              message: this.translateTitle(`注册成功`),
              type: "success",
            });
            this.registerSuccess(data, status, params);
          }
          // this.registerSuccess(data, status, params);
        }
        if (this.currentType == 1) {
          //账号
          delete params.account;
          delete params.google_code;
          params.google_secret = this.secret;
          params.username = this.registerData.account;
          params.google_code = this.codeData.google_code;
          this.submitLoading = true;
          const { data, status } = await Account.accountSignUp(params);
          this.submitLoading = false;
          if (status == 200) {
            showMessage({
              message: this.translateTitle(`注册成功`),
              type: "success",
            });
            this.registerSuccess(data, status, params);
          }
        }
        if (this.currentType == 2) {
          // 邮箱
          delete params.area;
          delete params.mobile;
          delete params.mobile_code;
          this.submitLoading = true;
          const { data, status } = await Account.emailReg(params);
          this.submitLoading = false;
          if (status == 200) {
            showMessage({
              message: this.translateTitle(`注册成功`),
              type: "success",
            });
            this.registerSuccess(data, status, params);
          }
          // this.registerSuccess(data, status, params);
        }
      });
    },
    registerSuccess(data, status, params) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "register", //註冊或登入
        // authenticationMethod: "email", //註冊方式（不一定要）
        userId: data.account_id, //用戶的平台UID
      });
      if (status === 200) {
        //TODO 直接登录
        // 邮箱
        if (this.currentType == 2) {
          this.$router.push({
            name: "Login",
            params: { username: params.email },
          });
          // 手机
        }
        if (this.currentType == 1) {
          this.$router.push({
            name: "Login",
            params: { username: params.username },
          });
        } else {
          this.$router.push({
            name: "Login",
            params: { username: params.mobile },
          });
        }
      }
    },
    clickFetch() {
      this.isFetch = true;
      // this.isShow = true;
      this.isShowGeet = !this.isShowGeet;
    },
    /* 倒计时 */
    async fetchCode() {
      let {
        currentType,
        registerData: { area, mobile, email },
      } = this;

      // 手机
      if (currentType === "3") {
        if (this.mobileCountDown.countDownStatus) return;
        // if (!mobile || /^\s*$/.test(mobile)) {
        //   this.$refs.register_ref.validateField("mobile");
        //   this.mobileCountDown.countDownStatus = false;
        //   return;
        // }
        // const { status } = await sendSignSmsCode({
        const { status } = await sendSignSmsCodeGeet({
          type: 2,
          mobile,
          area,
          ...this.geetParam,
        });
        if (status == 200) {
          this.mobileCountDown.countDownStatus = true;
          showMessage({
            message: this.translateTitle(`验证码发送成功`),
            type: "success",
            ...this.geetParam,
          });
          this.mobileCountDown.countDown = 60;
          this.mobileCountDown.countDownTimer = setInterval(() => {
            if (this.mobileCountDown.countDown === 0) {
              clearInterval(this.mobileCountDown.countDownTimer);
              this.mobileCountDown.countDownStatus = false;
            }
            this.mobileCountDown.countDown--;
          }, 1000);
        } else {
          this.mobileCountDown.countDownStatus = false;
        }
        // 邮箱
      } else {
        if (this.mobileCountDown.countDownStatus) return;
        if (!email || /^\s*$/.test(email)) {
          this.$refs.register_ref.validateField("email");
          this.mobileCountDown.countDownStatus = false;
          return;
        }
        // const { status } = await sendSignEmailCode({
        const { status } = await sendSignEmailCodeGeet({
          type: 32,
          email,
          ...this.geetParam,
        });
        if (status == 200) {
          this.mobileCountDown.countDownStatus = true;
          showMessage({
            message: this.translateTitle(`验证码发送成功`),
            type: "success",
            ...this.geetParam,
          });
          this.mobileCountDown.countDown = 60;
          this.mobileCountDown.countDownTimer = setInterval(() => {
            if (this.mobileCountDown.countDown === 0) {
              clearInterval(this.mobileCountDown.countDownTimer);
              this.mobileCountDown.countDownStatus = false;
            }
            this.mobileCountDown.countDown--;
          }, 1000);
        } else {
          this.mobileCountDown.countDownStatus = false;
        }
      }
    },
    /* 重置 */
    resetForm() {
      this.$refs.register_ref.resetFields();
    },
    /* 获取手机区号 */
    async getAreaCode() {
      const { data } = await Settings.apiGetStaticTableInfo(
        Settings.SETTING_SV_ID.static_sms_country
      );
      if (!data) {
        return false;
      }
      this.all_areacode = data;
    },

    /* 初始数据 */
    initialData() {
      this.registerData = {
        area: 86,
        mobile: "",
        email: "",
        pwd: "",
        account: "",
        confirm_pwd: "",
        // mobile_code: "",
        // email_code: "",
        // google_code: "",
        invite_code: "",
      };
    },
  },
  watch: {
    currentType(val) {
      // 手机
      if (val == "3") {
        // 停止邮箱相关
        clearInterval(this.emailCountDown.countDownTimer);
        this.emailCountDown.countDown = -1;
        this.emailCountDown.countDownStatus = false;
        return;
      }

      // 邮箱
      if (val == "2") {
        // 停止手机相关
        clearInterval(this.mobileCountDown.countDownTimer);
        this.mobileCountDown.countDown = -1;
        this.mobileCountDown.countDownStatus = false;
        return;
      }
    },
  },

  computed: {
    ...mapGetters({
      language: "user/language",
    }),
    googleCode() {
      let name;
      let { secret } = this;
      let account = this.registerData.account || "";
      name = encryption(account);
      if (secret) {
        let str = secret.slice(secret.length - 6);
        let val = `otpauth://totp/(${name}-${str})?secret=${secret}`;
        val = val.replace(/\s+/g, "");
        return val;
      } else {
        return "";
      }
    },
  },
  components: { InputNumber, QrcodeVue, Vcode, Geetest },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.emailCountDown.countDownTimer);
    clearInterval(this.mobileCountDown.countDownTimer);
    next();
  },
};
</script>

<style lang="scss">
.zZindex {
  z-index: 3000 !important;
}

.register_container_bv {
  width: 100%;
  // min-width: 1260px;
  height: calc(100vh - 60px);
  text-align: left;
  // background: url("../../assets/svg/login-bg.png");
  // background-size: 100% 100%;
  background-color: #112132;
  min-height: 600px;
  box-sizing: border-box;
  padding-top: -70px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  .bgvideo {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .invite-code {
    .code {
      font-size: 12px;
      color: #9aa1b1;
      font-weight: 400;
      margin-bottom: 5px;
    }
    i {
      font-size: 10px;
      cursor: pointer;
    }
  }
  .resiger-verification {
    //  background-image: linear-gradient(174deg, #19273c 12%, #273244 92%);
    .el-dialog__header {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: #354052;
    }
    .el-dialog__body {
      padding: 0px 50px 30px 50px;
      .sub-title {
        text-align: center;
        font-size: 14px;
        color: #354052;
        font-weight: 400;

        .mobile-email {
          -webkit-touch-callout: text; /* iOS Safari */
          -webkit-user-select: text; /* Chrome/Safari/Opera */
          -khtml-user-select: text; /* Konqueror */
          -moz-user-select: text; /* Firefox */
          -ms-user-select: text; /* Internet Explorer/Edge */
          user-select: text;
        }
      }
      .email-title {
        color: #9aa1b1;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-top: 10px;
      }
      .code-form {
        margin-top: 30px;
        .el-input__inner {
          width: 400px;
          height: 54px;
        }
        .footer-btootns {
          .submit {
            width: 400px;
            height: 48px;
            line-height: 48px;
            padding: 0px;
          }
          .get-code {
            display: flex;
            margin-top: 20px;
            align-items: center;
            justify-content: center;
            .title {
              font-size: 14px;
              color: #354052;
              font-weight: 400;
            }
            .count-down {
              width: 70px;
              color: #3a7dff;
              text-align: center;
              .code-title {
                cursor: pointer;
              }
              .disabled {
                color: #9aa1b1;
              }
            }
          }
        }
      }
      .gogle-details {
        margin-bottom: 20px;
        .gogle-remark {
          span {
            height: 34px;
            font-size: 12px;
            font-weight: 400;
            color: #9aa1b1;
            line-height: 17px;
          }
        }

        .qr_code {
          height: 110px;
          display: flex;
          align-items: flex-end;
          // padding-bottom: 44px;
          box-sizing: border-box;
          .qr_code_canvas {
            width: 110px;
            height: 110px;
            padding: 5px;

            // border: 1px solid #b4bac6;
            // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
            background-color: #e2e2e4;
            margin-right: 20px;
          }
          i {
            font-size: 15px;
            cursor: pointer;
            color: #9aa1b1;
            margin-left: 5px;
            &:hover {
              color: #9aa1b1;
            }
          }
        }
      }
    }
  }
  /* 登录 */
  .el-button--primary {
    border-color: #c61b3d !important;
  }
  .rigister {
    width: 500px;
    min-height: 464px;
    margin: 0px auto 0;
    // background: rgba(255, 255, 255, 1);
    // border-radius: 4px;
    border-radius: 24px;
    backdrop-filter: blur(27px);
    background-color: rgba(255, 255, 255, 0.16);
    // position: relative;
    z-index: 5;
    position: absolute;
    ///邀请码样式

    h1 {
      // color: #333;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      padding: 23px 31px 29px;
      padding-bottom: 0;
      text-align: center;
    }
    .tab-change-o {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .tab-change {
      height: 54px;
      padding: 10px 60px 0;
      margin-top: 23px;
      display: flex;
      .tabs {
        .el-tabs__nav-wrap {
          &:after {
            background-color: transparent;
          }

          /* 选项 */
          .el-tabs__nav-scroll {
            background-color: rgba(216, 216, 216, 0.54);
            border-radius: 20px;
            padding: 0 5px;
          }
          .el-tabs__item {
            color: #333;
            // color: #fff;
            font-size: 14px;
            font-weight: normal;
            span {
              padding: 5px 15px;
            }
          }
          .is-active {
            span {
              color: #fff;
              padding: 5px 15px;
              background-color: #f7b239;
              border-radius: 20px;
            }
          }

          /* 底部条 */

          .el-tabs__active-bar {
            // background: #ffffff;
            background: transparent;
            content: "";

            &:after {
              content: "";
              height: 3px;
              width: 20px;
              // background-color: #c61b3d;
              background-color: transparent !important;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    /* 表单 */

    .rigister-form {
      padding: 10px 50px 0px 50px;

      .el-form {
        input {
          // height: 44px;
          border-radius: 2px;

          &:focus {
            border-color: #27c683;
          }
        }

        /* 每一项 */

        .el-form-item {
          padding-top: 3px;
          margin-bottom: 15px !important;
          .el-form-item__content {
            .el-input {
              .el-input__inner {
                background-color: transparent;
                border-top-width: 0px;
                border-left-width: 0px;
                border-right-width: 0px;
                border-bottom: 1px solid #d8d8d8;
                color: #d8d8d8;
                padding-left: 0;
                &::placeholder {
                  color: #d8d8d8;
                }
              }
            }
          }
        }

        /* 区号-手机号 */

        .area-mobile {
          margin-top: 0px;
          display: flex;

          .el-form-item {
            // .el-select .el-input.is-focus .el-input__inner {
            //   border-color: #27c683;
            // }

            &:first-child {
              width: 89.7px;
              margin-right: 6px;

              input {
                padding-right: 20px;
              }
            }

            &:last-child {
              flex: 1;
            }
          }
        }

        /* 获取验证码 */

        .verification-code {
          .verification {
            position: relative;

            .count-down {
              width: 80px;
              color: #3a7dff;
              line-height: 1em;
              text-align: center;
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              .disabled {
                color: #9aa1b1;
              }

              span {
                cursor: pointer;
              }
            }
          }
        }

        /* 邮箱 */

        .email {
          margin-top: 0px;
        }

        /* 登录 */

        .rigister-btn {
          width: 100%;
          height: 48px;
          color: #fff;
          font-size: 16px;
          line-height: 48px;
          text-align: center;
          border-radius: 2px;
          background-image: linear-gradient(
            264deg,
            #f7b239,
            #f7b239
          ) !important;
          margin-top: 10px;
          border: 0;
          padding: 0;
          cursor: pointer;

          &:hover {
            border: 0;
            background-image: linear-gradient(
              264deg,
              #f7b239,
              #f7b239
            ) !important;
          }
        }
      }
      .gogle-details {
        .gogle-remark {
          span {
            height: 34px;
            font-size: 12px;
            font-weight: 400;
            color: #9aa1b1;
            line-height: 17px;
          }
        }

        .qr_code {
          height: 120px;
          display: flex;
          align-items: flex-end;
          // padding-bottom: 44px;
          box-sizing: border-box;
          .qr_code_canvas {
            width: 110px;
            height: 110px;
            padding: 5px;

            // border: 1px solid #b4bac6;
            // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
            background-color: #e2e2e4;
            margin-right: 20px;
          }
          i {
            font-size: 15px;
            cursor: pointer;
            color: #9aa1b1;
            margin-left: 5px;
            &:hover {
              color: #9aa1b1;
            }
          }
        }
      }
    }

    /* 去注册-忘记密码 */

    .register-forget {
      // color: #333;
      color: #fff;
      font-size: 14px;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      padding: 10px 50px;
      padding-bottom: 30px;
      .register {
        margin-left: -3px;
      }

      a {
        color: #f7b239;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .agreement {
    padding: 10px 0px;
    .el-checkbox__label {
      word-break: break-all;
      white-space: break-spaces;
      vertical-align: top;
    }
    .el-checkbox__inner {
      border-radius: 50% !important;
    }
    .el-checkbox__input.is-checked .el-checkbox__inne {
      background-color: #f7b239 !important;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #f7b239;
      border-color: #f7b239;
    }
    .user-agreement {
      font-size: 14px;
      color: #f7b239;
      font-weight: 400;
    }
    .title {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .el-input__suffix-inner {
    img {
      width: 18px;
      height: 16px;
      padding: 1px 0;
      color: #e2e2e4;
      cursor: pointer;
      line-height: 48px;
      margin-top: 20px;
      margin-right: 15px;
      // padding-right: 15px;
    }
    .open {
      margin-top: 22px;
      width: 18px;
      height: 12px;
      color: #c2c7d0;
    }
  }
  .el-input__inner {
    height: 48px;
  }

  /* 版权 */

  .copyright {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    font-size: 14px;
    text-align: center;
    color: #fff;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #c2c7d0 !important;
    font-weight: 400;
    font-size: 14px;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #c2c7d0 !important;
    font-weight: 400;
    font-size: 14px;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #c2c7d0 !important;
    font-weight: 400;
    font-size: 14px;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #c2c7d0 !important;
    font-weight: 400;
    font-size: 14px;
  }
  .el-dialog {
    background: #112132;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__body {
      color: #fff;
      .sub-title {
        color: #fff;
      }
      .email-title {
        color: #fff;
      }
      .code-form .footer-btootns .get-code {
        .title {
          color: #fff;
        }
        .count-down {
          color: #f7b239;
        }
      }
      .el-input__inner {
        // background-color: transparent;
      }
    }
  }
}
</style>
